import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

import MainLayout from '../layouts';

import ErrorBoundary from './Common/ErrorBoundary';
import Loading from '../components/Common/Loading';
import { PrivateRoute } from '../utils/PrivateRoute';

const Login = React.lazy(() => import('./../views/Login'));
const OpsAlert = React.lazy(() => import('./../components/Alert/OpsAlert'));
const FAIPAlert = React.lazy(() => import('./../components/Alert/FAIPAlert'));
const FAMemberAlert = React.lazy(() => import('./../components/Alert/FAMemberAlert'));
const ComboGhostWagerDetail = React.lazy(() => import('./../components/Common/ComboGhost/WagerDetail'));
const OneSidedEvnetWagerDetail = React.lazy(() => import('./../components/EventLevelReport/OneSidedEvent/OneSidedEventWagerDetail'));
const MemberLog = React.lazy(() => import('./MemberSetting/MemberListing/MemberLog/MemberLogListing'));
const InstantBetAlert = React.lazy(() => import('./../components/Alert/InstantBetAlert'));
const FreezeView = React.lazy(() => import('./../components/Alert/InstantBetAlert/FreezeView'));
const EventSettlementTaggingTableComponent = React.lazy(() => import('./EventLevelReport/EventSettlementTagging/EventSettlementTaggingTableComponent'));
const InternalAuditByDays = React.lazy(() => import('./RiskManagement/InternalAudit/InternalAuditByDays'));
const InternalAuditByMonths = React.lazy(() => import('./RiskManagement/InternalAudit/InternalAuditByMonths'));
const InternalAuditWagerDetail = React.lazy(() => import('./RiskManagement/InternalAudit/InternalAuditWagerDetail'));
const InternalAuditByMember = React.lazy(() => import('./RiskManagement/InternalAudit/InternalAuditByMember'));
const TopPerformanceMemberCodeModal = React.lazy(() => import('./RiskManagement/RiskSummary/TopPerformanceCombo/TopPerformanceMemberCodeModal'));
const MemberPerformanceDetail = React.lazy(() => import('./MemberLevelReport/MemberPerformance/MemberDetail'));
const MemberProfilingDetail = React.lazy(() => import('./MemberLevelReport/MemberProfiling/MemberProfilingDetail'));
const AGBActionReasonDetail = React.lazy(() => import('./../components/WagerLevelReport/AGB/AGBActionReasonDetail'));
const WagerDetail = React.lazy(() => import('./Common/WagerDetail'));
const MemberDashboardSystemRejectionDetail = React.lazy(() => import('./MemberSetting/MemberDashboard/SystemRejectionDetail'));
const MLTaggingWagerReport = React.lazy(() => import('./MemberLevelReport/MLTaggingWagerLevel'));
const ArberBetReportDetail = React.lazy(() => import('./WagerLevelReport/ArberBetReport/ArberBetReportDetail'));
const ArberBet = React.lazy(() => import('./Common/ArberBet'));
const BetTogetherDetail = React.lazy(() => import('./MemberSetting/MemberDashboard/BetTogetherDetail'));

class App extends Component {
    render() {
        return (
            <ErrorBoundary>
                <BrowserRouter>
                    <React.Suspense fallback={<Loading />}>
                        <Switch>
                            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                            <Route exact path="/alert/OpsAlert" render={(props) => <OpsAlert {...props} />} />
                            <Route exact path="/alert/FAIPAlert" render={(props) => <FAIPAlert {...props} />} />
                            <Route exact path="/alert/FAMemberAlert" render={(props) => <FAMemberAlert {...props} />} />
                            <Route exact path="/alert/InstantBetAlert" render={(props) => <InstantBetAlert {...props} />} />
                            <Route
                                exact
                                path="/wagerlevelreport/ComboGhostWagerDetail/:reportType/:fullCoverN/:eventDate/:eventID/:comboGroupID/:selectionAggID/:ipAddress/:maxOdds"
                                render={(props) => <ComboGhostWagerDetail {...props} />}
                            />
                            <Route
                                exact
                                path="/eventlevelreport/OneSidedEventWagerDetail/:eventID/:eventDate/:wagerTypeID/:comboGroupID/:periodID/:betTypeID/:selection/:market"
                                render={(props) => <OneSidedEvnetWagerDetail {...props} />}
                            />
                            <Route exact path="/alert/instantbetalert/freeze" render={(props) => <FreezeView {...props} />} />
                            <Route exact path="/membersetting/memberlog/:module/:offlineexportmodule" render={(props) => <MemberLog {...props} />} />
                            <Route exact path="/membersetting/memberlog" render={(props) => <MemberLog {...props} />} />
                            <Route
                                exact
                                path="/eventlevelreport/eventsettlementtagging/tablecomponent/:module/:sportIDs/:periodIDs/:startDate/:endDate/:settlementTypeIDs/:settlementStatusID/:competitionID"
                                render={(props) => <EventSettlementTaggingTableComponent {...props} />}
                            />
                            <Route
                                exact
                                path="/riskmanagement/InternalAuditByDays/:startDate/:endDate/:userCode/:userName/:actionLogID/:userStatusID/:roleID/:memberCode/:monthStr/:month/:year/:wagerNo/:eventID/:dimension/:dimensionValue/:wagerTypeID/:cancelReason/:unCancelReason"
                                render={(props) => <InternalAuditByDays {...props} />}
                            />
                            <Route
                                exact
                                path="/riskmanagement/InternalAuditByMonths/:startDate/:endDate/:userCode/:userName/:actionLogID/:userStatusID/:roleID/:memberCode/:year/:wagerNo/:eventID/:dimension/:dimensionValue/:wagerTypeID/:cancelReason/:unCancelReason"
                                render={(props) => <InternalAuditByMonths {...props} />}
                            />
                            <Route
                                exact
                                path="/riskmanagement/InternalAuditWagerDetail/:startDate/:endDate/:userCode/:userStatusID/:roleID/:memberCode/:day/:month/:year/:actionLogID/:wagerNo/:eventID/:dimension/:dimensionValue/:wagerTypeID/:cancelReason/:unCancelReason/:isCancelledWagerType"
                                render={(props) => <InternalAuditWagerDetail {...props} />}
                            />
                            <Route
                                exact
                                path="/riskmanagement/InternalAuditByMember/:startDate/:endDate/:userCode/:userStatusID/:roleID/:actionLogID/:memberCode/:wagerNo/:eventID/:dimension/:dimensionValue/:wagerTypeID/:cancelReason/:unCancelReason/:isCancelledWagerType"
                                render={(props) => <InternalAuditByMember {...props} />}
                            />
                            <Route
                                exact
                                path="/memberlevelreport/MemberDetail/:dateFrom/:dateTo/:companyIDs/:sportIDs/:platformIDs/:memberSettingProfileIDs/:isAdvisedIDs/:soccerTypes/:basketBallTypes/:memberCategoryID/:winLossGroupID/:offset/:limit"
                                render={(props) => <MemberPerformanceDetail {...props} />}
                            />
                            <Route
                                exact
                                path="/memberlevelreport/MemberProfilingDetail/:dateFrom/:dateTo/:companyIDs/:sportIDs/:memberSettingProfileIDs/:spreadGroupIDs/:isAdvisedIDs/:statusIDs/:platformIDs/:updatedBy/:soccerTypes/:basketBallTypes/:memberCategoryID"
                                render={(props) => <MemberProfilingDetail {...props} />}
                            />
                            <Route
                                exact
                                path="/riskmanagement/risksummary/topperformancecombo/tablecomponent/:memberCode"
                                render={(props) => <TopPerformanceMemberCodeModal {...props} />}
                            />
                            <Route exact path="/wagerlevelreport/AGB/AGBActionReasonDetail/:agbId/:actionReasonId" render={(props) => <AGBActionReasonDetail {...props} />} />
                            <Route
                                exact
                                path="/common/WagerDetail/:dateFrom/:dateTo/:maxCreatedDate/:performanceTypeName/:memberCode/:companyID/:companyName/:isCancelled/:isByDangerType/:wagerCancelReasonTypeID/:dangerReasonTypeID/:seacrhPermissionID/:exportPermissionID/:offlineExportModuleID/:settledDateFrom/:settledDateTo/:isDefaultFilterAmt/:eventID/:eventName/:sportID/:sportName/:betTypeID/:betTypeName/:handicap/:marketID/:betTypeSelectionID/:betTypeSelectionName/:sportIDs/:otherCancelReasonName/:wagerTypeID"
                                render={(props) => <WagerDetail {...props} />}
                            />
                            <Route
                                exact
                                path="/membersetting/SystemRejectionDetail/:byBetAttempt/:dateFrom/:dateTo/:lastUpdatedDT/:memberID/:memberCode/:companyID/:statusCode/:eventID/:periodID/:betTypeID/:marketIDs/:sportID/:handicap/:betTypeSelectionID/:comboSelectionID/:score"
                                render={(props) => <MemberDashboardSystemRejectionDetail {...props} />}
                            />
                            <Route
                                exact
                                path="/memberlevelreport/MLTaggingWagerLevel/:memberCode/:companyName/:companyID"
                                render={(props) => <MLTaggingWagerReport {...props} />}
                            />
                            <Route
                                exact
                                path="/WagerLevelReport/ArberBet/ArberBetDetail/:arberBetID/:eventID/:sportID/:marketID/:betTypeID/:betTypeSelectionID/:periodID/:arberAppearSec/:createdDate"
                                render={(props) => <ArberBetReportDetail {...props} />}
                            />
                            <Route exact path="/Common/ArberBet/:dateFrom/:dateTo/:memberID/:sportID/:marketIDs/:wagerActionID" render={(props) => <ArberBet {...props} />} />
                            <Route
                                exact
                                path="/membersetting/MemberDashboard/BetTogetherDetail/:memberID/:memberCode/:companyID/:category/:marketIDs/:wagerTypeID/:minStakeAmount/:maxStakeAmount/:sportIDs/:soccerTypes/:basketballTypes/:betTypeIDs"
                                render={(props) => <BetTogetherDetail {...props} />}
                            />
                            <PrivateRoute path="/" component={(props) => <MainLayout {...props} />} />
                        </Switch>
                    </React.Suspense>
                </BrowserRouter>
            </ErrorBoundary>
        );
    }
}

export default App;
