import * as signalR from '@microsoft/signalr';
import * as signalRMsgPack from '@microsoft/signalr-protocol-msgpack';
import { loadFromLocalStorage } from '../storageHelper';
import storageConst from '../../constants/storage';

const apiGBDashboardUrl = process.env.REACT_APP_API_GB_DASHBOARD;

// const OneMinuteReconnectPolicy = (function () {
//     function OneMinuteReconnectPolicy() {}

//     OneMinuteReconnectPolicy.prototype.nextRetryDelayInMilliseconds = function (retryContext) {
//         return retryContext.elapsedMilliseconds < 6000000 ? 3000 : null;
//     };

//     return OneMinuteReconnectPolicy;
// })();

class ReconnectPolicy {
    nextRetryDelayInMilliseconds(retryContext) {
        return retryContext.elapsedMilliseconds < 6000000 ? 5000 : null;
    }
}

const SocketConnection = (hubName = '') => {
    const reconnectPolicy = new ReconnectPolicy();
    // console.log('SocketConnection');
    const currentUser = loadFromLocalStorage(storageConst.CURRENT_USER);
    if (currentUser && currentUser.token) {
        const socket = new signalR.HubConnectionBuilder()
            .withUrl(`${apiGBDashboardUrl}/hubs/${hubName}`, {
                headers: { Authorization: 'bearer ' + currentUser.token },
                accessTokenFactory: () => {
                    // Get and return the access token.
                    // This function can return a JavaScript Promise if asynchronous
                    // logic is required to retrieve the access token.
                    return currentUser.token;
                },
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .withHubProtocol(new signalRMsgPack.MessagePackHubProtocol())
            .withAutomaticReconnect(reconnectPolicy)
            .configureLogging(signalR.LogLevel.Information)
            .build();
        socket.onreconnected((connectionId) => {
            console.log(`Client id: ${connectionId} reconnected!`);
        });
        socket.onclose(async (error) => {
            console.log(`onclose: ${error}`);
            await socket.start();
        });
        socket.onreconnecting((error) => {
            console.log(`onreconnecting: ${error}`);
        });

        socket.startConnection = () => {
            socket
                .start()
                .then(
                    (data) => {
                        socket.invoke('JoinRoomAsync', hubName);
                        console.log(data);
                        console.log(socket.connectionId);
                    },
                    // socket.invoke('send', 'Hello');
                )
                .catch((err) => {
                    console.error(err);
                    setTimeout(socket.startConnection(), 5000); // Restart connection after 5 seconds.
                });
        };

        socket.startConnection();

        socket.join = (roomName) => {
            console.log('join room', roomName);
            socket.emit('joinRoom', roomName);
            if (!socket.rooms) socket.rooms = [roomName];
            else socket.rooms?.push(roomName);
        };

        socket.leave = (roomName) => {
            console.log('leave room', roomName);
            socket.emit('leaveRoom', roomName);
            socket.rooms = socket.rooms?.filter((e) => e !== roomName);
        };

        socket.leaveAllRooms = () => {
            console.log('leave all rooms');
            socket.emit('leaveAllRooms');
            socket.rooms = [];
        };
        return socket;
    } else return undefined;
};

export default SocketConnection;
