import moment from 'moment';

export function toUIDateTimeStr(datetime) {
    return moment.utc(datetime).add(parseInt(process.env.REACT_APP_TIMEZONE, 10), 'hours').format('YYYY-MM-DD HH:mm:ss');
}

export function ifNullToUIDateTimeStr(datetime, nullStr = '-') {
    if (datetime !== undefined && datetime !== null && datetime !== '')
        return moment.utc(datetime).add(parseInt(process.env.REACT_APP_TIMEZONE, 10), 'hours').format('YYYY-MM-DD HH:mm:ss');
    return nullStr;
}

export function toISODateTimeStr(datetime) {
    return moment.utc(datetime).toISOString();
}

export function toProcessDateStr(datetime) {
    return moment(datetime).format('YYYYMMDD');
}

export function toDateStr(datetime) {
    return moment(datetime).format('YYYY-MM-DD');
}

export function toTimeStr(datetime) {
    return moment(datetime).format('HH:mm:ss');
}

export function toUIDateStr(datetime) {
    return moment.utc(datetime).add(parseInt(process.env.REACT_APP_TIMEZONE, 10), 'hours').format('YYYY-MM-DD');
}

export function toUITimeStr(datetime) {
    return moment.utc(datetime).add(parseInt(process.env.REACT_APP_TIMEZONE, 10), 'hours').format('HH:mm:ss');
}

export function toDateTimeStr(datetime) {
    return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
}

export function toUTCDateTimeStr(datetime) {
    return moment.utc(datetime).format('YYYY-MM-DD HH:mm:ss');
}

export function toSFDateTimeUTCStr(datetime) {
    return moment(datetime).add(parseInt(-process.env.REACT_APP_TIMEZONE, 10), 'hours').format('YYYY-MM-DDTHH:mm:ss');
}

export function toDateTimeUTCStr(datetime) {
    return moment(datetime).format('YYYY-MM-DDTHH:mm:ss');
}

export function toSGDateStr(datetime) {
    return moment(datetime).format('YYYY-MM-DD');
}

export function toSGDateTimeStr(datetime) {
    return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
}

export function utcToSGDateTimeStr(datetime) {
    return moment(datetime).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
}

export function toUIDate(datetime) {
    return new Date(moment.utc(datetime).add(parseInt(process.env.REACT_APP_TIMEZONE, 10), 'hours').format('YYYY-MM-DDTHH:mm:ss'));
}

export function getUIDatetime() {
    let subtractHour = 8 - parseInt(process.env.REACT_APP_TIMEZONE, 10);
    return getSubtractHour(new Date(), subtractHour);
}

export function getTodayUIDate() {
    let subtractHour = 8 + -parseInt(process.env.REACT_APP_TIMEZONE, 10);
    let currentDatetime = getSubtractHour(new Date(), subtractHour);
    let currDT = new Date(currentDatetime);
    return new Date(currDT.getFullYear(), currDT.getMonth(), currDT.getDate());
}

export function getTomorrowUIDate() {
    let subtractHour = 8 + -parseInt(process.env.REACT_APP_TIMEZONE, 10);
    let currentDatetime = getSubtractHour(new Date(), subtractHour);
    let currDT = new Date(currentDatetime);
    return new Date(currDT.getFullYear(), currDT.getMonth(), getAdvanceDateByDay(currDT, 1));
}

export function getDateOfWeek(date, noDay = 1) {
    let tmpDate = new Date(date);
    tmpDate.setDate(tmpDate.getDate() - 1);
    return moment(tmpDate).weekday(noDay).toDate();
}

export function getFirstDateOfMonth() {
    return moment().startOf('month').toDate();
}

export function getLastDateOfMonth() {
    return moment().endOf('month').toDate();
}

export function getDaysInMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function dateTruncDay(date) {
    let y = date.getFullYear().toString();
    let m = (date.getMonth() + 1).toString();
    let d = date.getDate();
    m.length === 1 && (m = '0' + m);
    return new Date([y, m, d].join('-') + ' 00:00:00');
}

export function dateTruncMonth(date) {
    let y = date.getFullYear().toString();
    let m = (date.getMonth() + 1).toString();
    let d = '01';
    m.length === 1 && (m = '0' + m);
    return new Date([y, m, d].join('-'));
}

export function dateTruncYear(date) {
    let y = date.getFullYear().toString();
    let m = '01';
    let d = '01';
    return new Date([y, m, d].join('-'));
}
export function getAdvanceDateBySecond(date, addSecond) {
    let tmpDate = new Date(date);
    return new Date(tmpDate.setSeconds(tmpDate.getSeconds() + addSecond));
}

export function getAdvanceDateByDay(date, addDay) {
    let tmpDate = new Date(date);
    return new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() + addDay);
}

export function getAdvanceDateByMonth(date, addMonth) {
    let tmpDate = new Date(date);
    return new Date(tmpDate.getFullYear(), tmpDate.getMonth() + addMonth, tmpDate.getDate());
}

export function getAdvanceMonthDate(date, addMonth) {
    let tmpDate = new Date(date);
    return new Date(tmpDate.getFullYear(), tmpDate.getMonth() + addMonth, 1);
}

export function getPastDate(subtractDay) {
    let now = new Date();
    return now.setDate(now.getDate() - subtractDay);
}

export function getPastMonthDate(subtractMonth) {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth() - subtractMonth, 1);
}

export function getPastMonthToday(subtractMonth) {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth() - subtractMonth, now.getDate());
}

export function getPastDateStr(subtractDay) {
    let now = new Date();
    now = now.setDate(now.getDate() - subtractDay);
    return toDateStr(now);
}

export function getSubtractedDateStr(date, subtractDay) {
    let tmpDate = new Date(date);
    return toDateStr(tmpDate.setDate(date.getDate() - subtractDay));
}

export function getSubtractSecond(date, subtractSecond) {
    let tmpDate = new Date(date);
    return tmpDate.setSeconds(tmpDate.getSeconds() - subtractSecond);
}

export function getSubtractedDate(date, subtractDay) {
    let tmpDate = new Date(date);
    return tmpDate.setDate(date.getDate() - subtractDay);
}

export function getSubtractedMonth(date, subtractMonth, day = 1) {
    let tempDate = new Date(date);
    return new Date(new Date(tempDate).getFullYear(), new Date(tempDate).getMonth() - subtractMonth, day);
}

export function getLastDate(date) {
    let tempDate = new Date(date);
    return moment(tempDate).endOf('month').toDate();
}

export function getSubtractHour(date, subtractHour) {
    let tmpDate = new Date(date);
    return tmpDate.setHours(tmpDate.getHours() - subtractHour);
}

export function getDefaultMonthMemberTagging() {
    let date = new Date();
    if (date.getDate() >= 7) {
        date.setMonth(date.getMonth() - 1);
        return date;
    } else {
        date.setMonth(date.getMonth() - 2);
        return date;
    }
}

export function converDateStrToDate(datestr) {
    let year = datestr.substring(0, 4);
    let month = datestr.substring(4, 6);
    let date = datestr.substring(6, 8);
    return new Date(year + '-' + month + '-' + date);
}

export function getDiffTwoDates(date, subtractDay, format) {
    return date.diff(subtractDay, format);
}

export function subtractHours(date, hours) {
    date.setHours(date.getHours() - hours);
    return date;
}

export function addHours(date, hours) {
    date.setHours(date.getHours() + hours);
    return date;
}

export function getMaxDate(dates) {
    // Filter out null values from the dates array
    const validDates = dates.filter((date) => date !== null);

    // If all dates are null, return today's date
    if (validDates.length === 0) {
        return new Date();
    }

    // Convert dates to timestamps and find the maximum
    const maxTimestamp = Math.max(...validDates.map((date) => new Date(date).getTime()));

    // Convert the maximum timestamp back to a Date object
    return new Date(maxTimestamp);
}

export function getLastDayOfPreviousXMonths(monthsAgo) {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    // Calculate the target month
    let targetMonth = currentMonth - monthsAgo;
    let year = currentYear;

    // Adjust the year if the target month is negative
    if (targetMonth < 0) {
        year -= Math.ceil(Math.abs(targetMonth) / 12);
        targetMonth = (12 + (targetMonth % 12)) % 12;
    }

    // Get the last day of the target month
    const lastDayOfTargetMonth = new Date(year, targetMonth + 1, 0);

    return lastDayOfTargetMonth;
}

export function getTimeDifference(createdAt) {
    const now = new Date();
    const createdTime = new Date(createdAt);
    const differenceInMilliseconds = now - createdTime;

    // Convert the difference to minutes, hours, and days
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    if (differenceInMinutes < 60) {
        return `${differenceInMinutes} minutes ago`;
    } else if (differenceInHours < 24) {
        return `${differenceInHours} hours ago`;
    } else {
        return `${differenceInDays} days ago`;
    }
}

export function getMonthDifference(date1, date2) {
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();

    return yearDiff * 12 + monthDiff;
}
