exports.PermissionSideMenuDisplay = {
    9: 'Generate Report',
    10: 'Export To GCS',
    11: 'Download Report',
    12: 'Alert',
    13: 'Abnormal Bet',
    14: 'Abnormal Bet',
    15: 'System Settings',
    16: 'User',
    17: 'Role',
    26: 'Support',
    27: 'Daily ETL',
    28: 'FAIP',
    29: 'FAIP',
    34: 'FAIP Whitelist',
    39: 'General Setting',
    42: 'Feature On/Off',
    45: 'Late Bet',
    46: 'Late Bet',
    51: 'Member Behaviour Change',
    54: 'Member Tagging from ML Techniques',
    57: 'Member Grouping (DeviceId)',
    60: 'FAMember',
    61: 'FAMember',
    66: 'FAMember Whitelist',
    70: 'Tableau Report',
    71: 'FAIP',
    73: 'Monitoring',
    74: 'Member Category Whitelist',
    78: 'Combo Ghost - Full Cover Combination',
    79: 'Combo Ghost - Full Cover Combination',
    84: 'ComboGhost - High Exposure Wager',
    85: 'ComboGhost - High Exposure Wager',
    90: 'Live Wager Discrepancy',
    91: 'Monitoring Dashboard',
    93: 'Member Summary',
    97: 'Profile Group',
    101: 'Ops Alert',
    102: 'One Sided Event',
    103: 'One Sided Event',
    108: 'Late Bet - Member Summary',
    111: 'Member Settings',
    112: 'Member Listing',
    116: 'Instant Bet',
    118: 'Member Grouping (Members Cookie)',
    123: 'Connectivity',
    124: 'Overall Bet Rejection Monitoring',
    127: 'FAMember Blacklist',
    131: 'Member Bet Rejection Monitoring',
    134: 'Data Mapping',
    139: 'SF Data Extraction',
    140: 'Risk Summary',
    143: 'Event Settlement',
    146: 'Theoretical Winloss Margin',
    149: 'Event Uptime Monitoring',
    152: 'Internal Audit',
    157: 'Offline Export Usage',
    158: 'Wager Report',
    161: 'Member Performance',
    164: 'Member Profiling',
    167: 'Member Mass Update',
    170: 'Member Dashboard',
    173: 'Score Profile',
    177: 'BQ Costing',
    178: 'GB IPIS Connectivity',
    179: 'Data Download',
    180: 'Risk Management',
    181: 'Member Level Report',
    182: 'Wager Level Report',
    183: 'Event Level Report',
    187: 'ML Tagging Wager Level',
    190: 'Arber Member Summary',
    193: 'Arber Bet Report',
    199: 'Bet Attempt Report',
    209: 'Data Recon Report',
    210: 'Event',
    213: 'ML Tagging UI Level',
    219: 'Member Category Settings',
    223: 'Late Bet - Member Summary',
    227: 'Notifications',
};
