import commonConst from '../../src/constants/commonConst';
import sf from '../../src/constants/sfConst';
import { GroupBy } from './arrayHelper';

export function formatDate(date, dateSeparator) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join(dateSeparator);
}

export function formDropdownListWithOption(sourceList, nameKey = 'label', valKey = 'value', options = {}) {
    const {
        includeUnknown = false,
        includeAll = false,
        includeNoChange = false,
        includeNull = false,
        includeCombo = false,
        includeBlank = false,
        includeNA = false,
        includeNoAction = false,
    } = options;

    return formDropdownList(sourceList, nameKey, valKey, includeUnknown, includeAll, includeNoChange, includeNull, includeCombo, includeBlank, includeNA, includeNoAction);
}

export function formDropdownList(
    sourceList,
    nameKey = 'label',
    valKey = 'value',
    includeUnknown = false,
    includeAll = false,
    includeNoChange = false,
    IncludeNull = false,
    includeCombo = false,
    includeBlank = false,
    includeNA = false,
    includeNoAction = false,
    includeSelect = false,
) {
    let tempList = sourceList.map((x) => {
        return {
            label: x[nameKey],
            value: x[valKey],
            ...(x.bgColor ? { bgColor: x.bgColor } : {}),
            ...(x.fontColor ? { fontColor: x.fontColor } : {}),
            ...(x.defaultMemberCategory ? { defaultMemberCategory: x.defaultMemberCategory } : {}),
        };
    });

    if (includeNoAction === true) tempList.unshift({ label: commonConst.NOACTION, value: -998 });

    if (includeUnknown === true) tempList.unshift({ label: commonConst.UNKNOWN, value: -9 });

    if (includeCombo === true) tempList.unshift({ label: commonConst.COMBO, value: -9 });

    if (includeNoChange === true) tempList.unshift({ label: commonConst.NOCHANGE, value: 999 });

    if (includeBlank === true) tempList.unshift({ label: commonConst.BLANK, value: -999 });

    if (includeAll === true) tempList.unshift({ label: commonConst.ALL, value: -1 });

    if (IncludeNull === true) tempList.unshift({ label: commonConst.NULL, value: null });

    if (includeNA === true) tempList.unshift({ label: commonConst.NA, value: null });

    if (includeSelect === true) tempList.unshift({ label: commonConst.SELECT, value: null });

    return tempList;
}

export function formActionDropdownList(sourceList, nameKey, valKey, includeUnknown = false, includeAll = false) {
    let tempList = [];
    tempList.push({ label: commonConst.NOCHANGE, value: -8 });
    return tempList.concat(formDropdownList(sourceList, nameKey, valKey, includeUnknown, includeAll));
}

export function handleParamSelectedAllOption(selectedOption, from = 'value', optionList = null) {
    let includedAll =
        selectedOption === null ||
        selectedOption.length === 0 ||
        (selectedOption !== null && selectedOption.length > 0 && selectedOption.map((x) => x.label).includes(commonConst.ALL));
    return includedAll ? (optionList === null ? [] : optionList.map((x) => x[from])) : selectedOption.map((x) => x[from]);
}

export function formDropdownListWithGroup(sourceList, nameKey, valKey, groupKey, includeUnknown = false, includeAll = false, includeNoChange = false, IncludeNull = false) {
    let tempList = sourceList.map((x) => {
        return {
            label: x[nameKey],
            value: x[valKey],
            ...(x.groupName !== '' ? { groupName: x.groupName } : {}),
            ...(x.bgColor ? { bgColor: x.bgColor } : {}),
            ...(x.fontColor ? { fontColor: x.fontColor } : {}),
        };
    });

    let grouped = GroupBy(tempList, 'groupName');
    let groupedList = [];

    Object.keys(grouped).forEach((key) => {
        if (key !== 'undefined' && key !== '') groupedList.push({ label: key, options: grouped[key] });
        else
            grouped[key].forEach((x) => {
                return groupedList.push(x);
            });
    });

    if (includeUnknown === true) groupedList.unshift({ label: commonConst.UNKNOWN, value: -9 });

    if (includeAll === true) groupedList.unshift({ label: commonConst.ALL, value: -1 });

    if (includeNoChange === true) groupedList.unshift({ label: commonConst.NOCHANGE, value: 999 });

    if (IncludeNull === true) groupedList.unshift({ label: commonConst.NULL, value: null });

    return groupedList;
}

export function formulateDDLGroupListWithSelectedValues(selectedOptions, defaultOptionList) {
    let tmpSelectedGrouped = GroupBy(selectedOptions, 'groupName');
    delete tmpSelectedGrouped[''];
    let selectedVales = selectedOptions.map((x) => x.value);
    let tmpOptionList = [...defaultOptionList];
    let AllList = [],
        selectedSingleList = [],
        selectedGroupedList = [];

    tmpOptionList = tmpOptionList.filter((x) => {
        if (x.label === commonConst.ALL) {
            AllList.push(x);
            return false;
        }
        if (selectedVales.includes(x.value)) {
            selectedSingleList.push(x);
            return false;
        }
        if (x.options !== undefined) {
            if (x.options?.length === tmpSelectedGrouped[x.label]?.length) {
                selectedGroupedList.push(x);
                return false;
            } else {
                x.options.map((xo) => {
                    if (selectedVales.includes(xo.value)) {
                        selectedSingleList.push(xo);
                    }
                    return xo;
                });
            }
        }

        return true;
    });

    return AllList.concat(selectedSingleList.concat(selectedGroupedList.concat(tmpOptionList)));
}

export function convertAGBBetStatusListToArr(betStatusList) {
    let responseData = [];
    responseData.isCancelled = -1;
    responseData.isSettled = -1;

    if (betStatusList.includes(sf.AGBBetStatus.isCancelled.toString())) {
        //Cancelled
        responseData.isCancelled = 1;
    }

    if (betStatusList.includes(sf.AGBBetStatus.isSettled.toString())) {
        //Settled
        responseData.isSettled = 1;
    }

    if (betStatusList.includes(sf.AGBBetStatus.Open.toString())) {
        //Open
        if (betStatusList.includes(sf.AGBBetStatus.isCancelled.toString()) && betStatusList.includes(sf.AGBBetStatus.isSettled.toString())) {
            responseData.isSettled = -1;
            responseData.isCancelled = -1;
        } else {
            responseData.isSettled = responseData.isSettled === -1 ? 0 : responseData.isSettled;
            responseData.isCancelled = responseData.isCancelled === -1 ? 0 : responseData.isCancelled;
        }
    }

    return responseData;
}
