export const PRE_GET_LIST = 'common/PRE_GET_LIST';
export const GET_LIST_SUCCESS = 'common/GET_LIST_SUCCESS';
export const GET_LIST_SUCCESS_WITHRAWDATA = 'common/GET_LIST_SUCCESS_WITHRAWDATA';
export const GET_LIST_FAILED = 'common/GET_LIST_FAILED';
export const PAGINATION_CHANGED = 'common/PAGINATION_CHANGED';
export const SET_VALIDATEERRORS = 'common/SET_VALIDATEERRORS';

// set common model visible
export const OPEN_MODAL = 'common/OPEN_MODAL';
export const CLOSE_MODAL = 'common/CLOSE_MODAL';
export const OPEN_MODAL_FAILED = 'common/OPEN_MODAL_FAILED';
export const OPEN_SECONDMODAL = 'common/OPEN_SECONDMODAL';
export const CLOSE_SECONDMODAL = 'common/CLOSE_SECONDMODAL';
export const OPEN_SECONDMODAL_FAILED = 'common/OPEN_SECONDMODAL_FAILED';
export const OPEN_EXPORTDIALOGMODAL = 'common/OPEN_EXPORTDIALOGMODAL';
export const CLOSE_EXPORTDIALOGMODAL = 'common/CLOSE_EXPORTDIALOGMODAL';

export const OPEN_ADDEDITVIEW_MODAL = 'common/OPEN_ADDEDITVIEW_MODAL';
export const CLOSE_ADDEDITVIEW_MODAL = 'common/CLOSE_ADDEDITVIEW_MODAL';

export const OPEN_AUDIT_MODAL = 'common/OPEN_AUDIT_MODAL';
export const SETUP_AUDIT_MODAL = 'common/SETUP_AUDIT_MODAL';
export const OPEN_AUDIT_MODAL_FAILED = 'common/OPEN_AUDIT_MODAL_FAILED';
export const CLOSE_AUDIT_MODAL = 'common/CLOSE_AUDIT_MODAL';

// set modal data
export const SET_MODAL_DATA = 'common/SET_MODAL_DATA';

export const ADDEDIT_ITEM = 'common/ADDEDIT_ITEM';
export const ADDEDIT_ITEM_SUCCESS = 'common/ADDEDIT_ITEM_SUCCESS';
export const ADDEDIT_ITEM_FAILED = 'common/ADDEDIT_ITEM_FAILED';

export const INIT_DROPDOWNLIST = 'common/INIT_DROPDOWNLIST';
export const SET_INPUTVALUE = 'common/SET_INPUTVALUE';
export const SET_SINGLEINPUTVALUE = 'common/SET_SINGLEINPUTVALUE';
export const SET_DATA = 'common/SET_DATA';
export const RESET_ERRMSG = 'common/RESET_ERRMSG';
export const RESET_SEARCHSTATE = 'common/RESET_SEARCHSTATE';

// 2FA
export const OPEN_2FATOKEN_MODAL = 'common/OPEN_2FATOKEN_MODAL';
export const CLOSE_2FATOKEN_MODAL = 'common/CLOSE_2FATOKEN_MODAL';
export const VERIFY_TWOFATORTOKEN = 'common/VERIFY_TWOFATORTOKEN';
export const VERIFY_TWOFATORTOKEN_DONE = 'common/VERIFY_TWOFATORTOKEN_DONE';

// common tab selection
export const SET_SELECTED_TAB = 'common/SET_SELECTED_TAB';

// Post-Message Modal
export const CLOSE_MESSAGE_MODAL = 'common/CLOSE_MESSAGE_MODAL';

// export
export const OPEN_EXPORT_MODAL = 'common/OPEN_EXPORT_MODAL';
export const CLOSE_EXPORT_MODAL = 'common/CLOSE_EXPORT_MODAL';
export const PRE_EXPORT_LIST = 'common/PRE_EXPORT_LIST';
export const EXPORT_LIST_SUCCESS = 'common/EXPORT_LIST_SUCCESS';
export const EXPORT_LIST_FAILED = 'common/EXPORT_LIST_FAILED';
export const SET_EXPORTLIST = 'common/SET_EXPORTLIST';
export const SET_EXPORTDATA = 'common/SET_EXPORTDATA';
export const EXPORT_SET_ISOPENEXPORT = 'common/EXPORT_SET_ISOPENEXPORT';

export const SET_ALERTSHOWTABLE = 'common/SET_ALERTSHOWTABLE';
export const SET_FREEZEDATALIST = 'common/SET_FREEZEDATALIST';
export const SET_SELECTEDDATALIST = 'common/SET_SELECTEDDATALIST';
export const SET_ALERTPAUSE = 'common/SET_ALERTPAUSE';

// bet attempt alert
export const RESET_COMPETITIONEVENTLIST = 'common/RESET_COMPETITIONEVENTLIST';
export const SET_COMPETITIONEVENTLIST = 'common/SET_COMPETITIONEVENTLIST';
export const SET_SELECTEDEVENTIDS = 'common/SET_SELECTEDEVENTIDS';
export const SET_SELECTEDCOMPETITIONEVENTIDS = 'common/SET_SELECTEDCOMPETITIONEVENTIDS';

export const SET_COMPETITIONLIST = 'common/SET_COMPETITIONLIST';
